<template>
  <div class="px-3" v-show="Object.keys(block).length">
    <div class="row my-2 text-setting">
      <div class="col-12">
        <b-form-group label="Select Payment Method">
          <b-form-radio
            v-if="getLoadQuizAppsIntegrated.includes('stripe')"
            v-model="block.selectedPaymentMethod"
            name="Stripe"
            value="Stripe"
            >Stripe</b-form-radio
          >
          <b-form-radio
            v-if="getLoadQuizAppsIntegrated.includes('paypal')"
            v-model="block.selectedPaymentMethod"
            name="Paypal"
            value="Paypal"
            >Paypal</b-form-radio
          >
        </b-form-group>
      </div>
      <div class="col-8 d-flex align-items-center pr-1">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.08333 3.33334V5.83334H6.24999V15.8333H8.74999V5.83334H12.9167V3.33334H2.08333ZM17.9167 7.50001H10.4167V10H12.9167V15.8333H15.4167V10H17.9167V7.50001Z"
            fill="#B3AFB6"
          />
        </svg>

        <select
          class="form-select w-100 form-control-sm input-border-style ml-1"
          aria-label="Default select example"
          v-model="block.style.fontFamily"
        >
          <option
            v-for="font in getFontFamilyList"
            :key="font.value"
            :value="font.value"
          >
            {{ font.value }}
          </option>
        </select>
      </div>
      <div class="col-12 d-flex align-items-center ml-3">
        <div class="row">
          <div class="col-6 px-0">
            <div class="d-flex align-items-center">
              <span class="mr-1">
                <svg
                  width="19"
                  height="13"
                  viewBox="0 0 19 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.6666 3.825H18.3333L15.8333 0.5L13.3333 3.825H14.9999V9.675H13.3333L15.8333 13L18.3333 9.675H16.6666V3.825Z"
                    fill="#B3AFB6"
                  />
                  <path
                    d="M0.833282 3V0.5H11.6666V3H7.49995V13H4.99995V3H0.833282Z"
                    fill="#B3AFB6"
                  />
                </svg>
              </span>
              <input
                type="number"
                min="0"
                max="100"
                style="width: 50px"
                v-model="block.style.fontSize"
                class="form-control-sm input-border-style"
              />
            </div>
          </div>
          <div class="col-6 px-0">
            <div class="d-flex align-items-center">
              <label for="" class="mb-0 mr-1 text-muted">W</label>
              <input
                type="number"
                v-model="block.style.width"
                class="form-control-sm w-100 input-border-style"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row my-2 d-flex align-items-center">
      <div class="col-5">
        <span
          class=""
          :class="{ 'active-svg': block.style.fontWeight === 'bold' }"
          @click="
            block.style.fontWeight =
              block.style.fontWeight == 'normal' ? 'bold' : 'normal'
          "
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 10 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.00001 5.99165C8.80834 5.43331 9.37501 4.51665 9.37501 3.66665C9.37501 1.78331 7.91668 0.333313 6.04168 0.333313H0.833344V12H6.70001C8.44168 12 9.79168 10.5833 9.79168 8.84165C9.79168 7.57498 9.07501 6.49165 8.00001 5.99165ZM3.33334 2.41665H5.83334C6.52501 2.41665 7.08334 2.97498 7.08334 3.66665C7.08334 4.35831 6.52501 4.91665 5.83334 4.91665H3.33334V2.41665ZM6.25001 9.91665H3.33334V7.41665H6.25001C6.94168 7.41665 7.50001 7.97498 7.50001 8.66665C7.50001 9.35831 6.94168 9.91665 6.25001 9.91665Z"
              fill="#B3AFB6"
            />
          </svg>
        </span>
        <span
          class="mx-1"
          :class="{ 'active-svg': block.style.fontStyle === 'italic' }"
          @click="
            block.style.fontStyle =
              block.style.fontStyle == 'normal' ? 'italic' : 'normal'
          "
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 10 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.33333 0.333313V2.83331H5.175L2.325 9.49998H0V12H6.66667V9.49998H4.825L7.675 2.83331H10V0.333313H3.33333Z"
              fill="#B3AFB6"
            />
          </svg>
        </span>
        <span
          class="mx-1"
          :class="{ 'active-svg': block.style.textDecoration === 'underline' }"
          @click="
            block.style.textDecoration =
              block.style.textDecoration == 'underline' ? 'unset' : 'underline'
          "
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 12 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.99999 12.1667C8.75832 12.1667 11 9.925 11 7.16667V0.5H8.91666V7.16667C8.91666 8.775 7.60832 10.0833 5.99999 10.0833C4.39166 10.0833 3.08332 8.775 3.08332 7.16667V0.5H0.99999V7.16667C0.99999 9.925 3.24166 12.1667 5.99999 12.1667ZM0.166656 13.8333V15.5H11.8333V13.8333H0.166656Z"
              fill="#B3AFB6"
            />
          </svg>
        </span>
        <span
          :class="{
            'active-svg': block.style.textDecoration === 'line-through',
          }"
          @click="
            block.style.textDecoration =
              block.style.textDecoration == 'line-through'
                ? 'unset'
                : 'line-through'
          "
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.33333 15.8333H11.6667V13.3333H8.33333V15.8333ZM4.16667 3.33334V5.83334H8.33333V8.33334H11.6667V5.83334H15.8333V3.33334H4.16667ZM2.5 11.6667H17.5V10H2.5V11.6667Z"
              fill="#B3AFB6"
            />
          </svg>
        </span>
      </div>
      <div class="col-4 d-flex align-items-center justify-content-around">
        <span
          :class="{ 'active-svg': block.style.textAlign === 'left' }"
          type="button"
          @click="block.style.textAlign = 'left'"
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.22223 9.2222H0.333344V10.7037H9.22223V9.2222ZM9.22223 3.29628H0.333344V4.77776H9.22223V3.29628ZM0.333344 7.74072H13.6667V6.25924H0.333344V7.74072ZM0.333344 13.6666H13.6667V12.1852H0.333344V13.6666ZM0.333344 0.333313V1.81479H13.6667V0.333313H0.333344Z"
              fill="#B3AFB6"
            />
          </svg>
        </span>
        <span
          :class="{ 'active-svg': block.style.textAlign === 'center' }"
          type="button"
          @click="block.style.textAlign = 'center'"
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.29631 9.2222V10.7037H10.7037V9.2222H3.29631ZM0.333344 13.6666H13.6667V12.1852H0.333344V13.6666ZM0.333344 7.74072H13.6667V6.25924H0.333344V7.74072ZM3.29631 3.29628V4.77776H10.7037V3.29628H3.29631ZM0.333344 0.333313V1.81479H13.6667V0.333313H0.333344Z"
              fill="#B3AFB6"
            />
          </svg>
        </span>
        <span
          :class="{ 'active-svg': block.style.textAlign === 'end' }"
          type="button"
          @click="block.style.textAlign = 'end'"
        >
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.166656 11.8334H11.8333V10.5371H0.166656V11.8334ZM4.05555 9.24076H11.8333V7.94447H4.05555V9.24076ZM0.166656 6.64817H11.8333V5.35187H0.166656V6.64817ZM4.05555 4.05558H11.8333V2.75928H4.05555V4.05558ZM0.166656 0.166687V1.46298H11.8333V0.166687H0.166656Z"
              fill="#B3AFB6"
            />
          </svg>
        </span>
      </div>
      <div class="col-3 mx-0 px-0">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 5.83332H7.08333L4.16667 2.91666L1.25 5.83332H3.33333V14.1667H1.25L4.16667 17.0833L7.08333 14.1667H5V5.83332ZM8.33333 4.16666V5.83332H18.3333V4.16666H8.33333ZM8.33333 15.8333H18.3333V14.1667H8.33333V15.8333ZM8.33333 10.8333H18.3333V9.16666H8.33333V10.8333Z"
            fill="#B3AFB6"
          />
        </svg>
        <input
          type="number"
          v-model="block.style.lineHeight"
          style="width: 50px"
          class="form-control-sm input-border-style"
        />
      </div>
    </div>
    <div class="form-group d-flex mb-2 align-items-center row">
      <div class="col-5 d-flex align-items-center pr-0 pl-2">
        <input
          type="color"
          v-model="block.style.color"
          style="min-width: 30px; width: 30px; height: 30px"
          class="form-control-color form-control border-0"
        />
        <!-- <span> {{ block.style.color }}</span> -->
        <input type="text" class="ColorTextInput" v-model="block.style.color" />
      </div>
      <div class="col-3 d-flex align-items-center px-0">
        <svg
          width="18"
          height="18"
          viewBox="0 0 14 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.7167 5.66665L7.00001 0.958313L2.28334 5.66665C0.983344 6.96665 0.333344 8.69998 0.333344 10.3666C0.333344 12.0333 0.983344 13.7916 2.28334 15.0916C3.58334 16.3916 5.29168 17.05 7.00001 17.05C8.70834 17.05 10.4167 16.3916 11.7167 15.0916C13.0167 13.7916 13.6667 12.0333 13.6667 10.3666C13.6667 8.69998 13.0167 6.96665 11.7167 5.66665ZM2.00001 10.6666C2.00834 8.99998 2.51668 7.94165 3.46668 6.99998L7.00001 3.39165L10.5333 7.04165C11.4833 7.97498 11.9917 8.99998 12 10.6666H2.00001Z"
            fill="#B3AFB6"
          />
        </svg>

        <input
          type="number"
          v-model="block.style.textOpacity"
          class="form-control-sm input-border-style w-100"
        />
      </div>
      <div class="col-3 d-flex align-items-center px-0">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.925 7.10834L4.75 5.92501C4 6.89168 3.53333 8.00834 3.39166 9.16668H5.075C5.19166 8.44168 5.48333 7.73334 5.925 7.10834ZM5.075 10.8333H3.39166C3.53333 11.9917 3.99166 13.1083 4.74166 14.075L5.91666 12.8917C5.48333 12.2667 5.19166 11.5667 5.075 10.8333ZM5.91666 15.2667C6.88333 16.0167 8.00833 16.4667 9.16666 16.6083V14.9167C8.44166 14.7917 7.74166 14.5083 7.11666 14.0583L5.91666 15.2667ZM10.8333 3.39168V0.833344L7.04166 4.62501L10.8333 8.33334V5.07501C13.2 5.47501 15 7.52501 15 10C15 12.475 13.2 14.525 10.8333 14.925V16.6083C14.125 16.2 16.6667 13.4 16.6667 10C16.6667 6.60001 14.125 3.80001 10.8333 3.39168Z"
            fill="#B3AFB6"
          />
        </svg>
        <input
          type="number"
          v-model="block.style.rotate"
          style="width: 50px"
          class="form-control-sm input-border-style"
        />
      </div>
    </div>
    <!-- Color Input end-->

    <!-- Answer options -->
    <div>
      <div class="form-group d-flex mt-3 align-items-center row">
        <div class="col-12">
          <span class="editorStyleTitle">Payment Title:</span>
        </div>
        <div class="col-12 px-0 mt-2">
          <div class="mx-3">
            <input
              type="text"
              class="py-1 w-100 form-control-input"
              style="
                border: none;
                border-radius: 2px;
                outline: none;
                border-bottom: 1px solid #e0dee2;
              "
              v-model="block.paymentTitle"
              placeholder="Enter Payment Title"
            />
          </div>
        </div>
      </div>

      <div class="">
        <div class="form-group d-flex mb-3 align-items-center row">
          <div class="col-12 mt-2">
            <span class="editorStyleTitle">Currency:</span>
            <div class="mt-2 d-flex">
              <div class="dropdown w-25">
                <button
                  class="btn lead-email-btn dropdown-toggle Currency-toggle w-100 text-left"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {{ block.selectedCurrency.currencyCode }}
                </button>
                <div
                  class="dropdown-menu w-100"
                  aria-labelledby="dropdownMenuButton"
                >
                  <button
                    class="dropdown-item w-100 d-flex justify-content-between align-items-center"
                    v-for="(currency, currencyIndex) in block.Currencies"
                    :key="currencyIndex"
                    @click="ChangeCurrency(currency)"
                    style="cursor: pointer"
                  >
                    <!-- @click="setInfoType(type, index)" -->
                    <div
                      class="d-flex justify-content-between align-items-center w-100"
                    >
                      <div>
                        <span>{{ currency.currencyCode }}</span>
                      </div>
                    </div>
                  </button>
                </div>
              </div>
              <div class="mx-3 px-2">
                <input
                  type="number"
                  min="0"
                  class="py-1 w-100 form-control-input"
                  style="
                    border: none;
                    border-radius: 2px;
                    outline: none;
                    border-bottom: 1px solid #e0dee2;
                  "
                  v-model="block.amountValue"
                  placeholder="Enter Amount"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="form-group mb-2 row">

                    </div> -->
        <div class="mb-2 row">
          <div class="col-12 px-4 d-flex align-items-center">
            <div class="show-logo custom-control custom-switch mr-4">
              <input
                type="checkbox"
                class="custom-control-input"
                :id="`RecurringPayment-${Date.now()}`"
                v-model="block.isRecuringPayment"
              />
              <label
                class="custom-control-label"
                :for="`RecurringPayment-${Date.now()}`"
              ></label>
            </div>
            <span class="font-weight-medium"
              ><strong> Recurring Payment:</strong></span
            >
          </div>
        </div>

        <div
          class="form-group d-flex mb-3 align-items-center row"
          v-if="block.isRecuringPayment"
        >
          <div class="col-12 mt-2">
            <div class="mt-2 d-flex">
              <div class="dropdown w-50">
                <button
                  class="btn lead-email-btn dropdown-toggle Currency-toggle w-100 text-left"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {{ block.selectedRecurringPayment.Text }}
                </button>
                <div
                  class="dropdown-menu w-100"
                  aria-labelledby="dropdownMenuButton"
                >
                  <button
                    class="dropdown-item w-100 d-flex justify-content-between align-items-center"
                    v-for="(payment, paymentIndex) in block.RecurringPayment"
                    :key="paymentIndex"
                    @click="ChangeRecurringPayment(payment)"
                    style="cursor: pointer"
                  >
                    <!-- @click="setInfoType(type, index)" -->
                    <div
                      class="d-flex justify-content-between align-items-center w-100"
                    >
                      <div>
                        <span>{{ payment.Text }}</span>
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="form-group d-flex mt-3 align-items-center row"
        v-if="block.selectedPaymentMethod == 'Stripe'"
      >
        <div class="col-12 px-0 mt-2">
          <div class="mx-3 mt-2">
            <button
              @click="getPaymentLink"
              class="outline-none w-100 SaveNavbar input-border-style px-3 py-1"
              style="
                cusrsor: pointer;
                background: #4d1b7e;
                color: #ffffff;
                font-family: inherit;
                font-size: 15px;
                font-weight: 500 !important;
                border-radius: 6px;
                width: 90px;
                height: 43px;
              "
            >
              <span v-if="!generatePaymentSpinner">Generate Payment Link</span>
              <div
                v-else
                class="spinner-border spinner-border-sm"
                role="status"
              >
                <span class="sr-only">Loading...</span>
              </div>
            </button>
          </div>
        </div>
        <div class="col-12 px-0 mt-3">
          <div class="mx-3">
            <input
              type="text"
              readonly
              class="py-1 w-100 form-control-input"
              style="
                border: none;
                border-radius: 2px;
                outline: none;
                border-bottom: 1px solid #e0dee2;
              "
              :value="block.stripePaymentLink"
              placeholder="Stripe Payment Link"
            />
          </div>
        </div>
      </div>
      <div
        class="form-group d-flex mt-3 align-items-center row"
        v-if="block.selectedPaymentMethod == 'Paypal'"
      >
        <div class="col-12 px-0 mt-2">
          <div class="mx-3 mt-2">
            <button
              @click="getPaypalPaymentLink"
              class="outline-none w-100 SaveNavbar input-border-style px-3 py-1"
              style="
                cusrsor: pointer;
                background: #4d1b7e;
                color: #ffffff;
                font-family: inherit;
                font-size: 15px;
                font-weight: 500 !important;
                border-radius: 6px;
                width: 90px;
                height: 43px;
              "
            >
              <span v-if="!generatePaymentSpinner">Generate Payment Data</span>
              <div
                v-else
                class="spinner-border spinner-border-sm"
                role="status"
              >
                <span class="sr-only">Loading...</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Date Input Start -->

    <!-- Date Input End -->

    <!-- Margin -->
    <div class="row m-0" v-if="isDesktopView">
      <h4 class="editorStyleTitle mt-4">Margins:</h4>
      <div class="row align-items-center">
        <div class="col-4 d-flex">
          <div class="row align-items-center">
            <div class="col-4">
              <svg
                width="14"
                height="16"
                viewBox="0 0 14 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.3334 7.16667H7.83337V15.5H6.16671V7.16667H3.66671L7.00004 3.83333L10.3334 7.16667ZM0.333374 2.16667V0.5H13.6667V2.16667H0.333374Z"
                  fill="#B3AFB6"
                />
              </svg>
            </div>
            <div class="col-8">
              <input
                type="number"
                v-model="block.style.marginTop"
                class="form-control-sm w-75 px-2 input-border-style"
              />
            </div>
          </div>
        </div>
        <div class="col-4 d-flex">
          <div class="row align-items-center">
            <div class="col-4">
              <svg
                width="14"
                height="16"
                viewBox="0 0 14 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.3334 8.83333H7.83337V0.5H6.16671V8.83333H3.66671L7.00004 12.1667L10.3334 8.83333ZM0.333374 13.8333V15.5H13.6667V13.8333H0.333374Z"
                  fill="#B3AFB6"
                />
              </svg>
            </div>
            <div class="col-8">
              <input
                type="number"
                v-model="block.style.marginBottom"
                class="form-control-sm w-75 px-2 input-border-style"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="form-group col-4 d-flex">
          <div class="row align-items-center">
            <div class="col-4">
              <svg
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.16667 3.66663V6.16663L15.5 6.16663V7.83329L7.16667 7.83329V10.3333L3.83333 6.99996L7.16667 3.66663ZM2.16667 13.6666L0.5 13.6666V0.333294L2.16667 0.333294V13.6666Z"
                  fill="#B3AFB6"
                />
              </svg>
            </div>
            <div class="col-8">
              <input
                type="number"
                v-model="block.style.marginLeft"
                class="form-control-sm w-75 px-2 input-border-style"
              />
            </div>
          </div>
        </div>
        <div class="form-group col-4 d-flex">
          <div class="row align-items-center">
            <div class="col-4">
              <svg
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.83333 3.66663V6.16663L0.5 6.16663L0.5 7.83329L8.83333 7.83329V10.3333L12.1667 6.99996L8.83333 3.66663ZM13.8333 13.6666L15.5 13.6666V0.333294L13.8333 0.333294V13.6666Z"
                  fill="#B3AFB6"
                />
              </svg>
            </div>
            <div class="col-8">
              <input
                type="number"
                v-model="block.style.marginRight"
                class="form-control-sm w-75 px-2 input-border-style"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-2" v-else>
      <h4 class="editorStyleTitle mt-4">Mobile Option margin:</h4>
      <div class="row m-0">
        <div class="row align-items-center">
          <div class="col-4 d-flex">
            <div class="row align-items-center">
              <div class="col-4">
                <svg
                  width="14"
                  height="16"
                  viewBox="0 0 14 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.3334 7.16667H7.83337V15.5H6.16671V7.16667H3.66671L7.00004 3.83333L10.3334 7.16667ZM0.333374 2.16667V0.5H13.6667V2.16667H0.333374Z"
                    fill="#B3AFB6"
                  />
                </svg>
              </div>
              <div class="col-8">
                <input
                  type="number"
                  min="0"
                  placeholder="Top"
                  v-model="block.style.mobileMarginTop"
                  class="form-control-sm w-75 px-2 input-border-style"
                />
              </div>
            </div>
          </div>
          <div class="col-4 d-flex">
            <div class="row align-items-center">
              <div class="col-4">
                <svg
                  width="14"
                  height="16"
                  viewBox="0 0 14 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.3334 8.83333H7.83337V0.5H6.16671V8.83333H3.66671L7.00004 12.1667L10.3334 8.83333ZM0.333374 13.8333V15.5H13.6667V13.8333H0.333374Z"
                    fill="#B3AFB6"
                  />
                </svg>
              </div>
              <div class="col-8">
                <input
                  type="number"
                  min="0"
                  placeholder="Bottom"
                  v-model="block.style.mobileMarginBottom"
                  class="form-control-sm w-75 px-2 input-border-style"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="form-group col-4 d-flex">
            <div class="row align-items-center">
              <div class="col-4">
                <svg
                  width="16"
                  height="14"
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.16667 3.66663V6.16663L15.5 6.16663V7.83329L7.16667 7.83329V10.3333L3.83333 6.99996L7.16667 3.66663ZM2.16667 13.6666L0.5 13.6666V0.333294L2.16667 0.333294V13.6666Z"
                    fill="#B3AFB6"
                  />
                </svg>
              </div>
              <div class="col-8">
                <input
                  type="number"
                  v-model="block.style.mobileMarginLeft"
                  min="0"
                  placeholder="Left"
                  class="form-control-sm w-75 px-2 input-border-style"
                />
              </div>
            </div>
          </div>
          <div class="form-group col-4 d-flex">
            <div class="row align-items-center">
              <div class="col-4">
                <svg
                  width="16"
                  height="14"
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.83333 3.66663V6.16663L0.5 6.16663L0.5 7.83329L8.83333 7.83329V10.3333L12.1667 6.99996L8.83333 3.66663ZM13.8333 13.6666L15.5 13.6666V0.333294L13.8333 0.333294V13.6666Z"
                    fill="#B3AFB6"
                  />
                </svg>
              </div>
              <div class="col-8">
                <input
                  type="number"
                  v-model="block.style.mobileMarginRight"
                  min="0"
                  placeholder="Right"
                  class="form-control-sm w-75 px-2 input-border-style"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import fontFamilyList from "../../../staticPages/fontFamilyList";
export default {
  props: {
    block: Object,
    isDesktopView: Boolean,
    pageId: Number,
  },
  data() {
    return {
      generatePaymentSpinner: false,
      isRecurringPaymentUpdated: false,
    };
  },
  computed: {
    ...mapGetters(["getQuizID", "getLoadQuizAppsIntegrated"]),
    getPaymentValue() {
      return this.block.amountValue;
    },
    getCurrencyCodeValue() {
      return this.block.selectedCurrency.currencyCode;
    },
    getPaymentLinkValue() {
      return this.block.stripePaymentLink;
    },
    getPaymentRecurringValue() {
      return this.block.isRecuringPayment;
    },
    getPaymentRecurringTimeValue() {
      return this.block.selectedRecurringPayment;
    },
    getCurrentPaymentMethod() {
      return this.block.selectedPaymentMethod;
    },
    getPaypalClientID() {
      return this.block.paypalClientID || "";
    },
    getFontFamilyList() {
      return fontFamilyList;
    },
    textBlockFont(){
      return this.block.style.fontFamily
    },
  },
  watch: {
    getPaymentValue(newVal, oldVal) {
      if (
        this.getCurrentPaymentMethod == "Stripe" &&
        this.getPaymentLinkValue != ""
      ) {
        if (newVal !== oldVal) {
          this.block.stripePaymentLink = "";
          this.$toasted.show("Payment Link removed.", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
      }
      if (
        this.getCurrentPaymentMethod == "Paypal" &&
        Object.keys(this.block.paypalPaymentDetail).length > 0
      ) {
        if (this.getPaymentRecurringValue) {
          this.isRecurringPaymentUpdated = true;
        } else {
          this.isRecurringPaymentUpdated = false;
        }
      }
    },

    getCurrencyCodeValue(newVal, oldVal) {
      if (
        this.getCurrentPaymentMethod == "Stripe" &&
        this.getPaymentLinkValue != ""
      ) {
        if (newVal !== oldVal) {
          this.block.stripePaymentLink = "";
          this.$toasted.show("Payment Link removed.", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
      }
      if (
        this.getCurrentPaymentMethod == "Paypal" &&
        Object.keys(this.block.paypalPaymentDetail).length > 0
      ) {
        if (this.getPaymentRecurringValue) {
          this.isRecurringPaymentUpdated = true;
        } else {
          this.isRecurringPaymentUpdated = false;
        }
      }
    },
    getPaymentRecurringValue(newVal, oldVal) {
      if (
        this.getCurrentPaymentMethod == "Stripe" &&
        this.getPaymentLinkValue != ""
      ) {
        if (newVal !== oldVal) {
          this.block.stripePaymentLink = "";
          this.$toasted.show("Payment Link removed.", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
      }
      if (
        this.getCurrentPaymentMethod == "Paypal" &&
        Object.keys(this.block.paypalPaymentDetail).length > 0
      ) {
        if (this.getPaymentRecurringValue) {
          this.isRecurringPaymentUpdated = true;
        } else {
          this.isRecurringPaymentUpdated = false;
        }
      }
    },
    getPaymentRecurringTimeValue(newVal, oldVal) {
      if (
        this.getCurrentPaymentMethod == "Stripe" &&
        this.getPaymentLinkValue != ""
      ) {
        if (newVal !== oldVal) {
          this.block.stripePaymentLink = "";
          this.$toasted.show("Payment Link removed.", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
      }
      if (
        this.getCurrentPaymentMethod == "Paypal" &&
        Object.keys(this.block.paypalPaymentDetail).length > 0
      ) {
        if (this.getPaymentRecurringValue) {
          this.isRecurringPaymentUpdated = true;
        } else {
          this.isRecurringPaymentUpdated = false;
        }
      }
    },
    getCurrentPaymentMethod: {
      immediate: true,
      handler(newVal) {
        if (newVal == "Paypal" && this.getPaypalClientID == "") {
          this.fetchPaypalClientID();
        }
      },
    },
    textBlockFont(newVal,oldVal){
      if(oldVal && newVal){
        this.$store.dispatch('updateFontFamily', { oldFontFamily:oldVal, newFontFamily:newVal });
        this.$store.dispatch('loadFontsFromTracker');
      }
    }
  },
  methods: {
    async fetchPaypalClientID() {
      try {
        let data = {
          quizId: parseInt(localStorage.getItem("QuizID")),
        };
        const response = await axios.get("/paypal/getClientData", {
          params: data,
        });

        if (response.status == 200) {
          this.block.paypalClientID = response.data.data.client_id;
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error Occured while fetching Paypal client ID", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
          throw error;
        }
      }
    },
    ChangeCurrency(value) {
      this.$emit("updateSelectedCurrency", value);
    },
    ChangeRecurringPayment(value) {
      this.$emit("updateRecurringPayment", value);
    },
    async getPaypalPaymentLink() {
      if (this.block.isRecuringPayment && this.block.paymentTitle == "") {
        this.$toasted.show(
          "Payment Title cannot be empty for recurring payments",
          {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          }
        );
        return;
      }

      this.generatePaymentSpinner = true;

      //check if recurring payment was updated then delete data first
      if (this.isRecurringPaymentUpdated) {
        let deleteResponse = await this.deletePaypalRecurringPaymentData();
        if (!deleteResponse) {
          this.$toasted.show("Error occured. Please try again!", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
          return;
        }
      }

      const data = {
        recursive: this.block.isRecuringPayment,
        quizId: parseInt(localStorage.getItem("QuizID")),
        page_id: this.pageId,
        amount: this.block.amountValue,
        currency_code: this.block.selectedCurrency.currencyCode,
      };

      if (this.block.isRecuringPayment) {
        data["product"] = {
          name: this.block.paymentTitle,
          type: "SERVICE",
        };

        data["plan"] = {
          name: this.block.paymentTitle,
          interval_unit:
            this.block.selectedRecurringPayment.value.toUpperCase(),
          total_cycles: 0,
        };
      }

      try {
        const response = await axios.post(
          `/paypal/save-payment-btn-details`,
          data
        );
        if (response.status == 200) {
          this.block.paypalPaymentDetail = response.data.data;

          this.$toasted.show("Payment Detail successfully created!", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.generatePaymentSpinner = false;
      }
    },
    async getPaymentLink() {
      if (this.block.paymentTitle != "") {
        try {
          this.generatePaymentSpinner = true;
          let data = {
            quizId: this.getQuizID,
            currency: this.block.selectedCurrency.currencyCode,
            amount: this.block.amountValue,
            amount_title: this.block.paymentTitle,
            is_subscription: this.block.isRecuringPayment ? 1 : 0,
          };

          if (this.block.isRecuringPayment == true) {
            data.subscription_duration =
              this.block.selectedRecurringPayment.value;
          }

          const response = await axios.post(`/generateStripePaymentLink`, data);
          if (response.status == 200) {
            if (response.data.ok) {
              this.$toasted.show(`${response.data.message}`, {
                theme: "toasted-primary",
                position: "bottom-center",
                duration: 2000,
              });
              this.block.stripePaymentLink = response.data.payment_link;
            } else {
              this.$toasted.show(`${response.data.message}`, {
                theme: "toasted-primary",
                type: "error",
                position: "bottom-center",
                duration: 2000,
              });
            }
          } else {
            this.$toasted.show(`${response.data.data}`, {
              theme: "toasted-primary",
              type: "error",

              position: "bottom-center",
              duration: 2000,
            });
          }
        } catch (error) {
          if (error) {
            this.$toasted.show("Error occured", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
          }
          throw error;
        } finally {
          this.generatePaymentSpinner = false;
        }
      } else {
        this.$toasted.show("Amount title is required", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
      }
    },
    async deletePaypalRecurringPaymentData() {
      try {
        const data = {
          quizId: parseInt(localStorage.getItem("QuizID")),
        };

        const response = await axios.post(
          `/paypal/delete-payment-btn-details`,
          data
        );
        if (response.status == 200) {
          this.isRecurringPaymentUpdated = false;
          return true;
        }
      } catch (error) {
        this.$toasted.show("Error occured while updating data", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
        return false;
      }
    },
  },
  mounted() {
    this.isRecurringPaymentUpdated = false;
  },
};
</script>
<style scoped>
.form-control-sn {
  border: 1px solid;
  width: 100%;
  border-radius: 3px;
  /* line-height: 1rem; */
  height: 26px;
  font-size: 14px;
}

.active-svg {
  padding: 3px;
  background: #b3afb6;
  border-radius: 3px;
}
.active-svg path {
  fill: #323232;
}

.Currency-toggle {
  position: relative;
}
.Currency-toggle::after {
  position: absolute;
  right: 0;
  top: 5px;
}
.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #ffc107 !important;
  border-color: #ffc107 !important;
}
.custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff !important;
}
</style>
